<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'组合营销'"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/combinedPackage/add">
            <el-button type="primary">新建套餐</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="套餐名称" fixed="left" prop="packageName" :min-width="180">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoDetail(scope.row)">{{ scope.row.packageName }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="套餐编号" prop="packageCode" :min-width="160"></el-table-column>
      <el-table-column label="场景" prop="categoryDesc" :min-width="230"></el-table-column>
      <el-table-column label="库存" prop="inventory" :min-width="150"></el-table-column>
      <el-table-column label="剩余库存" prop="availableInventory" :min-width="150"></el-table-column>
      <el-table-column label="兑换/购买单数" prop="orderNum" :min-width="150"></el-table-column>
      <el-table-column label="兑换/购买人数" prop="buyerNum" :min-width="150"></el-table-column>
      <el-table-column label="销售价格" prop="price" :min-width="150"></el-table-column>
      <el-table-column label="消耗积分" prop="integral" :min-width="150"></el-table-column>
      <el-table-column label="状态" prop="state" :min-width="150">
        <template slot-scope="scope">
          {{ scope.row.state | stateFilter}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="ctime" :min-width="180"></el-table-column>
      <!-- <el-table-column label="banner图片" prop="" :min-width="230">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px" :src="scope.row.bannerImage" :preview-src-list="[scope.row.bannerImage]"></el-image>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" fixed="right" prop="" width="200">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">查看数据</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">编辑</el-button>
<!--          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(3, scope.row)">更改状态</el-button>-->

          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(6, scope.row)" v-if="scope.row.state == 0">发布</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(7, scope.row)" v-if="scope.row.state == 1">结束</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(8, scope.row)" v-if="scope.row.state == 2 || scope.row.state == 1">草稿</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          customerName: "",     //客户姓名
          customerMobile: "",   //客户电话
          // userPhone: ""
        },
        searchKey: "customerName", //下拉input所选Key
        listLoading: false, //列表loading
        channelNameList: [                     //渠道名称list
          { channel: '', linkName: '' ,channelMobile: ''}
        ],
        promotionDialogVisible: false,        //推广信息弹窗
        rowActivityCode: "",                  //当前点击的活动code
        activityPopularizeList: [],           //活动推广列表
        url: "",
        rewardDialogVisible: false,
        commissionConfList: [],
        orderItems: [{
          "item":"createTime",
          "type":"desc"
        }],
      };
    },
  filters: {
    stateFilter: function(val) {
      switch (val) {
        case 0:
          return "未开始";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "";
      }
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //查看数据
          this.$router.push(`/combinedPackage/dashboard/${row.packageCode}`);
          break;
        case 2:     //编辑
          this.$router.push(`/combinedPackage/edit/${row.packageCode}`);
          break;
        case 6:     //更改状态为发布
          this.updateActivityState(row.packageCode,1);
          window.location.reload();
          break;
        case 7:     //更改状态为结束
          this.updateActivityState(row.packageCode,2);
          window.location.reload();

          break;
        case 8:     //更改状态草稿
          this.updateActivityState(row.packageCode,0);
          window.location.reload();
          break;
        default:
          break;
      }
    },
    // 跳转活动详情
    gotoDetail(row){
      this.$router.push(`/combinedPackage/dashboard/${row.packageCode}`);
    },



    // 添加\删除渠道
    channelNameFn(type,index){
      if(type == 'add'){
        this.channelNameList.push({ channel: '', linkName: '' })
      }
      if(type == 'del'){
        this.$confirm('是否删除此渠道?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.channelNameList.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },


    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        customerName: "",     //客户姓名
        customerMobile: "",   //客户电话
      }
    },



    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/getPackageList',{
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

    // 修改活动状态
    updateActivityState(packageCode,state) {
      this.$store.dispatch('management/updatePackageState',{
        packageCode: packageCode,
        state: state
      }).then(res => {

      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
